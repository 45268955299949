.wrapper {
  background: rgba(244, 241, 242, 0.8);

  padding: 15px;

  border-radius: 4px;
  align-self: flex-start;
}

.title {
  border-bottom: 2px solid rgba(255, 0, 0, 0.8);
  padding-bottom: 15px;
}

.post {
  padding: 15px 0;
  cursor: pointer;
  transform: all 0.2s;
  font-size: 15px;
  opacity: 0.9;

  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &_img {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
  &:hover {
    .post_title {
      color: brown;
    }
  }
}

.wrapper {
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 25px 20px;

  margin-bottom: 30px;
  .logo {
    color: white;
    cursor: pointer;
  }

  .search {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    input {
      padding: 5px;
      border: none;
      outline: none;
      border-radius: 2px;
      width: 80%;
    }
  }
}

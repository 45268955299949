.post {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 15px;
  cursor: pointer;
  transform: all 0.2s;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 5px solid rgba(255, 255, 255, 0.5);

  padding: 15px;
  padding-bottom: 35px;
  border-radius: 5px;

  width: 300px;
  height: 300px;

  color: white;

  position: relative;
  transition: all 0.2s;

  overflow: hidden;

  &_wrapper_img::before {
    content: "";
    background-image: linear-gradient(
      to top,
      rgba(33, 30, 32, 1),
      rgba(255, 255, 255, 0)
    );
    position: absolute;
    height: 180px;
    right: 5px;
    top: 35px;
    left: 5px;
  }
  &_wrapper_img::after {
    content: "";
    display: block;
    height: 9999px;
  }
  &_img {
    vertical-align: middle;
    object-fit: cover;

    height: 180px;
    width: 285px;
    margin-bottom: 10px;
    border-radius: 5px;

    position: absolute;
    top: 5px;
    right: 5px;
    left: 5px;

    z-index: -100000;
  }

  &_bottom {
    align-self: center;
    justify-self: center;
  }

  &_title {
    opacity: 0.9;
    font-size: 18px;
  }

  &_date {
    font-size: 11px;
    opacity: 0.7;
    margin-top: 10px;
  }

  &:hover {
    border: 1px solid white;
    border-bottom: 5px solid white;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  margin-right: 15px;

  .news_btn {
    align-self: center;
    background: none;
    border: none;
    border-radius: 2px;
    outline: 2px solid black;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    transform: all 0.2s;

    margin-top: 25px;
    &:hover {
      color: brown;
      outline: 2px solid brown;
    }
  }
}

.grid {
  display: grid;
  height: auto;
  align-items: flex-start;

  grid-template-columns: repeat(auto-fill, minmax(200px, max-content));
  grid-auto-rows: auto;
  gap: 30px;

  .first_post {
    grid-column: 1/1;
    grid-row: 1/3;
  }

  .post {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    cursor: pointer;
    transform: all 0.2s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &_img {
      object-fit: cover;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 5px;
    }

    &_title {
      opacity: 0.9;
    }

    &_date {
      font-size: 11px;
      opacity: 0.7;
      margin-top: 5px;
    }

    &:hover {
      .post_title {
        color: brown;
      }
    }
  }
}

.bold {
  font-weight: 700;
}

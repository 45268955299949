.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: black;
  padding: 15px;
  margin-top: 20px;
  .list {
    display: flex;
    flex-direction: column;

    list-style: none;

    color: white;
    li {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;

      transition: all 0.2s;
      margin-bottom: 5px;
      &:hover {
        color: brown;
      }
      p {
        margin-left: 5px;
      }
    }
  }
}

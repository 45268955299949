@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Roboto", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper-button-next,
.swiper-button-prev {
  color: rgba(255, 0, 0, 0.8) !important;
}

.swiper-slide {
  opacity: 0.25;
}
.swiper-container {
  overflow: visible;
}
.swiper-slide-visible {
  opacity: 1;
}
